import React, { useEffect, useState } from "react";
import Button from "components/atoms/Button/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { verifyAccount } from "store/slices/email.slice";
import Loading from "components/atoms/Loading/Loading";
import SegmentAgent from "apis/segmentAgent";
import { Container, Icon } from "components";

const EmailVerify: React.FC = () => {
  // const [iconType, setIconType] = useState<string>("received");
  const { isSuccess, errorMessage, successMessage, isLoading, data } = useAppSelector(
    (state) => state.email
  );
  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation()
  useEffect(() => {
    // SegmentAgent.page('authEmailVerify', {pathName: location.pathname} )
    window.analytics.page("Email Verification")

    dispatch(verifyAccount(token || ""));
  }, []);
  useEffect(() => {
    if (errorMessage) {
      // setIconType("decline");
    } else if (successMessage) {
      if (data) {
        // SegmentAgent.event({}, data.segmentUserId, 'ACCOUNT_EMAIL_VERIFIED', data.platformBrandId)
        window.analytics.track("Account Email Verified")
      }
      // setIconType("received");
    }
  }, [isSuccess]);
  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <div className={`flex flex-col gap-8 items-center justify-center h-screen`}>
        <div className="flex justify-center items-center bg-primary-500 w-32 h-32 rounded-full text-white-500">
          <Icon name="email" size={50} />
        </div>
        <div className="flex flex-col gap-3 text-center">
          <h3 className="text-2xl">Email Verification Successful</h3>
          <p>
            Your email has been successfully verified. You can now enjoy all the
            features of our platform.
          </p>
        </div>
        <Button onClick={() => navigate("/")}>Back to page</Button>
      </div>
    </Container>
  );
};

export default EmailVerify;
