import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getPersonalInfo } from "store/slices/user.slice";
import Loading from "components/atoms/Loading/Loading";
import AccountSettings from "components/molecules/AccountSettings/AccountSettings";
import PasswordChange from "components/molecules/AccountSettings/PasswordChange";
import { useLocation } from "react-router-dom";
import CompanyPixel from "components/molecules/CompanyPixel/CompanyPixel";
import CompanyPages from "components/molecules/CompanyPages/CompanyPages";
import { Container } from "components";

const MyAccount: React.FC = () => {
  const { isSuccess, data } = useAppSelector((state) => state.user);
  const isLoading = useAppSelector((state) => state.user.updateUser.isLoading);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { setValue } = useForm({mode: "onBlur" });
  // const save = (updatedPersonalInfo: any) => {
  //   const updatedUser = {
  //     firstName: updatedPersonalInfo.name,
  //     lastName: updatedPersonalInfo.lastName,
  //     newEmail: updatedPersonalInfo.email,
  //   };
  //   dispatch(updateUser(updatedUser));
  // };
  useEffect(() => {
    // SegmentAgent.page("accountSettings", { pathName: location.pathname });
    window.analytics.page("Account Settings")
    dispatch(getPersonalInfo());
  }, []);
  useEffect(() => {
    if (!isSuccess) return;
    setValue("name", data.firstName);
    setValue("email", data.email);
    setValue("lastName", data.lastName);
  }, [isSuccess]);
  if (isLoading) return <div style={{ position: "absolute", width: "100%", height: "100vh" }}><Loading /></div>
  return (
    <Container>
      <div className="flex flex-col items-center">
        <div className="flex flex-col w-full sm:w-3/6 gap-6 sm:gap-12">
          <AccountSettings user={data} />
          <PasswordChange />
          <CompanyPixel />
          <CompanyPages />
        </div>
      </div>
    </Container>
  )
};
export default MyAccount;