import React from 'react';
import {Checkbox} from "@mui/material";
import Menu from "../../atoms/Menu/Menu";
import constants from "../../../utils/constants";
import {flexRender, Header, RowData} from "@tanstack/react-table";
import {LeadProfileDTO} from "../../../utils/dtos/leadDTO";
import {InterfacePixelLeadData} from "../../../store/slices/insights";

type Props = {
    setAnchorExportSelectMenu: (value: (((prevState: (HTMLElement | null)) => (HTMLElement | null)) | HTMLElement | null)) => void
    isSelectAllChecked: boolean
    isAllListSelected: boolean
    resetExport: () => void
    isAllRowsInPageSelected: boolean
    handleSelectPeoplePage: () => void
    handleSelectAllPeople: () => void
    isExportSelectMenuOpen: boolean
    anchorExportSelectMenu:  HTMLElement | null
    count: number
    header:  Header<any, unknown>
}

const SelectAllCheckbox = ({setAnchorExportSelectMenu, isSelectAllChecked, count, isAllListSelected, resetExport, isAllRowsInPageSelected, handleSelectPeoplePage,
   handleSelectAllPeople, isExportSelectMenuOpen, anchorExportSelectMenu, header}: Props) => {
    return (
        <div className="flex items-center ml-1">
            <div onClick={(e) => setAnchorExportSelectMenu(e.currentTarget)}>
                <Checkbox
                    checked={isSelectAllChecked}
                    onClick={(e) => {
                        if (isAllListSelected) {
                            resetExport();
                            e.stopPropagation();
                            return;
                        }
                        // ⭐ Open menu
                        if (!isAllRowsInPageSelected && !isAllListSelected) e.preventDefault();
                        else {
                            e.stopPropagation();
                            handleSelectPeoplePage();
                        }
                    }}
                    sx={{ color: "var(--color-gray-600)", "&.Mui-checked": { color: "var(--color-primary-500)"},
                        "&:hover": { backgroundColor: "transparent" }}}
                />
            </div>
            <Menu
                items={[
                    { title: "Select this page", onClick: () => handleSelectPeoplePage(), },
                    { title: `Select all people (${count < constants.LIMIT_ENRICHMENT_ATTEMPT_PER_TASK ? count : constants.LIMIT_ENRICHMENT_ATTEMPT_PER_TASK.toLocaleString()})`,
                        onClick: () => handleSelectAllPeople() },
                ]}
                isOpen={isExportSelectMenuOpen}
                anchor={anchorExportSelectMenu}
                setAnchor={setAnchorExportSelectMenu}
            />
            {flexRender(header.column.columnDef.header,header.getContext())}
        </div>
    );
};

export default SelectAllCheckbox;
