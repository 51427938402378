import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import Icon from "components/atoms/Icon/Icon";
import InputSelect from "components/atoms/InputSelect/InputSelect";
import { GROUPED_OPTIONS_COMPANY, GROUPED_OPTIONS_JOBTITLE, OPTIONS_COMPANY, OPTIONS_COMPANY_HEADCOUNT, OPTIONS_DEPARTMENT, OPTIONS_GENDER, OPTIONS_INDUSTRY, OPTIONS_JOBTITLE, OPTIONS_LOCATION, OPTIONS_SENIORITY } from "./SearchMainHelper";
import Button from "components/atoms/Button/Button";
import InputField from "components/atoms/InputField/InputField";
import Tooltip from "components/atoms/Tooltip/Tooltip";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setTriggerApplyFilters } from "store/slices/ui.slice";
import {useSearchParams} from "react-router-dom";
import {SetStateFunction} from "../../../utils/filterHandlerWithGeneralOptions";


const SearchMainFilters: FC<{isGroupedOptions?: boolean, searchPeople: string | number;
  setSearchPeople: Dispatch<SetStateAction<string | number>>; searchLinkedin: any;
  setSearchLinkedin: Dispatch<SetStateAction<any>>; filterJobTitle: any[];
  setFilterJobTitle: SetStateFunction; filterCompany?: any[];
  setFilterCompany?: SetStateFunction ; filterIndustry: any[];
  setFilterIndustry: Dispatch<SetStateAction<any[]>>; filterLocationPerson: any[];
  setFilterLocationPerson: Dispatch<SetStateAction<any[]>>; filterGender?: any[];
  setFilterGender?: Dispatch<SetStateAction<any[]>>; filterSeniority?: any[];
  setFilterSeniority?: Dispatch<SetStateAction<any[]>>; filterDepartment?: any[];
  setFilterDepartment?: Dispatch<SetStateAction<any[]>>; filterLocationCompany?: any[];
  setFilterLocationCompany?: Dispatch<SetStateAction<any[]>>; filterCompanyHeadcount?: any[];
  setFilterCompanyHeadcount?: Dispatch<SetStateAction<any[]>>; filterIncomeRange?: any[];
  setFilterIncomeRange?: Dispatch<SetStateAction<any[]>>; filterCompanyRevenue?: any[];
  setFilterCompanyRevenue?: Dispatch<SetStateAction<any[]>>;
  setSearchPageVisited?: Dispatch<SetStateAction<string>>; searchPageVisited?: any;
  setSearchNumberPagesViewed?: Dispatch<SetStateAction<string>>; searchNumberPagesViewed?: any;
  setSearchNumberOfVisits?: Dispatch<SetStateAction<string>>; searchNumberOfVisits?: any;
  setSearchTimeOnPage?: Dispatch<SetStateAction<string>>; searchTimeOnPage?: any;
  setSearchLeadScore?: Dispatch<SetStateAction<string>>; searchLeadScore?: any;
  fetchResults: () => Promise<void>; isSearching: boolean;
  isAppliedFilter: boolean; setIsAppliedFilter: (newVal: boolean) => void; setPageCurrent: (newVal: number) => void;
  pageCurrent: number }> = ({ isGroupedOptions = false, searchPeople, setSearchPeople, searchLinkedin, setSearchLinkedin, filterGender,
  setFilterGender, filterJobTitle, setFilterJobTitle, filterCompany, setFilterCompany, filterIndustry,
  setFilterIndustry, filterLocationPerson, setFilterLocationPerson, filterLocationCompany, setFilterLocationCompany,
  filterSeniority, setFilterSeniority, filterDepartment, setFilterDepartment, filterCompanyHeadcount,
  setFilterCompanyHeadcount, fetchResults, isSearching, isAppliedFilter, setIsAppliedFilter, setPageCurrent,
  pageCurrent, setSearchPageVisited, searchPageVisited, searchNumberPagesViewed, setSearchNumberPagesViewed, searchNumberOfVisits, setSearchNumberOfVisits,
  searchTimeOnPage, setSearchTimeOnPage, searchLeadScore, setSearchLeadScore}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch();
  const triggerApplyFilters = useAppSelector((state) => state.ui.triggerApplyFilters);
  const filterDeps = [
    searchPeople,
    searchLinkedin,
    searchPageVisited,
    searchNumberPagesViewed,
    searchNumberOfVisits,
    searchTimeOnPage,
    searchLeadScore,
    filterJobTitle,
    filterCompany,
    filterIndustry,
    filterLocationPerson,
    filterGender,
    filterSeniority,
    filterDepartment,
    filterLocationCompany,
    filterCompanyHeadcount,
  ];
  const isDisabled = useMemo(() => {
    return filterDeps.every(dep => {
      if(dep !== undefined) {
        const value = dep?.toString();
        return value?.length === 0;
      } else {
        return true
      }
    });
  }, filterDeps);

  const resetFilters = useCallback(() => {
    setSearchPeople("");
    setSearchLinkedin("");
    setSearchPageVisited?.("");
    setSearchNumberPagesViewed?.("");
    setSearchNumberOfVisits?.("");
    setSearchTimeOnPage?.("");
    setSearchLeadScore?.("");
    setFilterJobTitle([]);
    setFilterCompany?.([]);
    setFilterIndustry?.([]);
    setFilterLocationPerson([]);
    setFilterGender?.([]);
    setFilterSeniority?.([]);
    setFilterDepartment?.([]);
    setFilterLocationCompany?.([]);
    setFilterCompanyHeadcount?.([]);
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.delete("name");
      newParams.delete("linkedin");
      newParams.delete("person_location");
      newParams.delete("gender");
      newParams.delete("job_title");
      newParams.delete("seniority");
      newParams.delete("department");
      newParams.delete("industry");
      newParams.delete("company_name");
      newParams.delete("company_location");
      newParams.delete("company_headcount");
      newParams.delete("page_visited");
      newParams.delete("number_pages_viewed");
      newParams.delete("number_of_visits");
      newParams.delete("time_on_page");
      newParams.delete("lead_score");
      // filterCompanyRevenue.length
      //     ? newParams.set("company_revenue", JSON.stringify(filterCompanyRevenue)) : newParams.delete("company_revenue");
      newParams.set("current_page", String(pageCurrent));
      return newParams;
    })
  }, filterDeps);
  const applyFilters = useCallback(() => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);

      const filters = [
        { key: "name", value: searchPeople },
        { key: "linkedin", value: searchLinkedin },
        { key: "person_location", value: filterLocationPerson },
        { key: "gender", value: filterGender },
        { key: "job_title", value: filterJobTitle },
        { key: "seniority", value: filterSeniority },
        { key: "department", value: filterDepartment },
        { key: "industry", value: filterIndustry },
        { key: "company_name", value: filterCompany },
        { key: "company_location", value: filterLocationCompany },
        { key: "company_headcount", value: filterCompanyHeadcount },
        { key: "page_visited", value: searchPageVisited },
        { key: "number_pages_viewed", value: searchNumberPagesViewed },
        { key: "number_of_visits", value: searchNumberOfVisits },
        { key: "time_on_page", value: searchTimeOnPage },
        { key: "lead_score", value: searchLeadScore },
      ];

      filters.forEach(({ key, value }) => {
        value?.length
          ? newParams.set(key, typeof value === "string" ? value : JSON.stringify(value))
          : newParams.delete(key);
      });

      newParams.set("current_page", String(pageCurrent));

      return newParams;
    })
    setIsAppliedFilter(true);
    setPageCurrent(1);
    // if (pageCurrent === 1) fetchResults();
  }, filterDeps);

  useEffect(() => {
    if (isDisabled) {
      resetFilters()
      // fetchResults();
      setIsAppliedFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled]);

  useEffect(() => {
    // when the value is updated from within another component's scope
    if(searchPeople && triggerApplyFilters) {
      applyFilters();
      dispatch(setTriggerApplyFilters(false));
    }
  }, [searchPeople, triggerApplyFilters])

  return (
    <>
      <div className="flex flex-row justify-between gap-2 px-5 pt-4 pb-1 w-full">
        <Tooltip isFullWidth content={isDisabled ? "Kindly select atleast one filter" : ""}>
          <Button
            disabled={isDisabled}
            onClick={() => applyFilters()}
            iconName="filter"
            iconSize={17}
            size="sm" className="w-full"
            isProcessing={isSearching}
            isProcessingTitle="Fetching..."
          >
            Apply Filters
          </Button>
        </Tooltip>
        <Button
          disabled={isDisabled} size="sm"
          onClick={() => resetFilters()}
          className="w-fit" iconName="trash" iconSize={14}
        >Clear</Button>
      </div>
      <ul id="menu-parent" className="SearchMainFilter">
        <FilterItem label="Name" icon="user-check" type="input" state={searchPeople} setState={setSearchPeople}
          isActive={searchPeople.toString().length > 0} placeholder="Search People" />
        <FilterItem label="Person Location" icon="user-location" state={filterLocationPerson}
          setState={setFilterLocationPerson} isActive={filterLocationPerson?.length > 0} options={OPTIONS_LOCATION}
          placeholder="Enter locations" />
        <FilterItem label="Linkedin" icon="linkedin" type="input" state={searchLinkedin}
          setState={setSearchLinkedin} isActive={searchLinkedin.toString().length > 0}
          placeholder="Enter a LinkedIn URL" />
        {/* PDL doesn't provide income range
        <FilterItem label="Income Range" icon="income"
          state={filterIncomeRange}
          setState={setFilterIncomeRange}
          isActive={filterIncomeRange.length > 0}
          options={OPTIONS_INCOME_RANGE}
          placeholder="Choose an income range"
          isSearchable={false}
        /> */}
        <FilterItem label="Job Title" icon="job" state={filterJobTitle} setState={setFilterJobTitle}
          isActive={filterJobTitle?.length > 0} options={isGroupedOptions ? GROUPED_OPTIONS_JOBTITLE : OPTIONS_JOBTITLE} placeholder="Enter job titles" />
        {filterSeniority && <FilterItem label="Seniority" icon="seniority" state={filterSeniority} setState={setFilterSeniority}
          isActive={filterSeniority?.length > 0} options={OPTIONS_SENIORITY} placeholder="Enter seniorities" />
        }
        {filterDepartment &&
            <FilterItem label="Department" icon="category" state={filterDepartment} setState={setFilterDepartment}
             isActive={filterDepartment?.length > 0} options={OPTIONS_DEPARTMENT} menuPlacement="top"
             placeholder="Enter departments" />
        }
        {filterIndustry &&
        <FilterItem label="Industry" icon="industry" state={filterIndustry} setState={setFilterIndustry}
          isActive={filterIndustry?.length > 0} options={OPTIONS_INDUSTRY} menuPlacement="top"
          placeholder="Enter industries" />
        }
        {filterCompany &&
        <FilterItem label="Company Name" icon="company" state={filterCompany} setState={setFilterCompany}
          isActive={filterCompany?.length > 0} options={isGroupedOptions ? GROUPED_OPTIONS_COMPANY : OPTIONS_COMPANY} menuPlacement="top"
          placeholder="Enter companies" />
        }
        {filterLocationCompany &&
        <FilterItem label="Company Location" icon="location" state={filterLocationCompany}
          setState={setFilterLocationCompany} isActive={filterLocationCompany?.length > 0} options={OPTIONS_LOCATION}
          menuPlacement="top" placeholder="Enter locations" />
        }
        {/* PDL doesn't provide company revnue
      <FilterItem label="Company Revenue" icon="revenue"
        state={filterCompanyRevenue} setState={setFilterCompanyRevenue}
        isActive={filterCompanyRevenue.length > 0}
        placeholder="Choose revenue"
        isSearchable={false}
        options={OPTIONS_COMPANY_REVENUE}
      /> */}
        {filterCompanyHeadcount &&
        <FilterItem label="Company Headcount" icon="people" state={filterCompanyHeadcount}
          setState={setFilterCompanyHeadcount} isActive={filterCompanyHeadcount?.length > 0}
          placeholder="Choose headcount" isSearchable={false} menuPlacement="top"
          options={OPTIONS_COMPANY_HEADCOUNT} />
        }
        {filterGender &&
        <FilterItem label="Gender" icon="gender" state={filterGender} setState={setFilterGender}
          isActive={filterGender?.length > 0} options={OPTIONS_GENDER} placeholder="Choose gender"
          isSearchable={false} />
        }
        {setSearchPageVisited &&
          <FilterItem label="Page Visited (page path)" icon="" type="input" state={searchPageVisited}
          setState={setSearchPageVisited} isActive={searchPageVisited.toString().length > 0}
          placeholder="Enter a page visited URL" />
        }
        {setSearchNumberPagesViewed &&
          <FilterItem label="Number of pages viewed" inputType={"number"} icon="" type="input" state={searchNumberPagesViewed}
            setState={setSearchNumberPagesViewed} isActive={searchNumberPagesViewed.toString().length > 0}
            placeholder="Enter a number of pages viewed" />
        }
        {setSearchNumberOfVisits &&
          <FilterItem label="Number of visits" inputType={"number"} icon="" type="input" state={searchNumberOfVisits}
            setState={setSearchNumberOfVisits} isActive={searchNumberOfVisits.toString().length > 0}
            placeholder="Enter a number of visits" />
        }
        {setSearchTimeOnPage &&
          <FilterItem label="Time on page (minimum)" inputType={"number"} icon="" type="input" state={searchTimeOnPage}
            setState={setSearchTimeOnPage} isActive={searchTimeOnPage.toString().length > 0}
            placeholder="Enter a time on page in seconds" />
        }
        {setSearchLeadScore &&
          <FilterItem label="Lead Score" inputType={"number"} icon="" type="input" state={searchLeadScore}
            setState={setSearchLeadScore} isActive={searchLeadScore.toString().length > 0}
            placeholder="Enter a lead score" />
        }
      </ul>
    </>
  );
};
export default SearchMainFilters;
const FilterItem: FC<any> = ({ type = "select", state, setState, label, labelInput, icon, isActive, options, placeholder, isSearchable = true, isMulti = true, menuPlacement = "auto", inputType= "text" as const }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState<string | number>("");
  useEffect(() => {
    if (state === searchInput || type !== "input") return;
    setSearchInput(state as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  return (
    <li
      className={`${isActive ? "is_active" : ""} ${isOpen ? "is_open" : ""}`}
      onClick={() => {
        if (!isOpen) setIsOpen(!isOpen);
      }}
    >
      <div className="content-title flex items-center justify-evenly gap-2" onClick={() => setIsOpen(!isOpen)}>
        <div className="w-full flex items-center gap-2">
          <Icon name={icon} size={20} />
          {label}
        </div>
        {isActive && (
          <Button
            size="xxs"
            onClick={(e: any) => {
              e.stopPropagation();
              setState([]);
            }}
          >
            {type === "input" ? 1 : state?.length}
            <Icon name="close" size={12} />
          </Button>
        )}
        <Icon name={isOpen ? "caret-up" : "caret-down"} size={15} />
      </div>
      {isOpen && (
        <div className="px-[20px] pt-0 pb-4">
          {type === "input" && (
            <form
              className="flex gap-2 items-center"
              onSubmit={(e) => {
                e.preventDefault();
                setState(searchInput);
              }}
            >
              <InputField type={inputType} variant="dark" placeholder={placeholder} size="small" value={state} onChange={(e: any) => setState(e.target.value)} />
              {/* <Button
                iconName="search"
                iconSize={16}
                size="xs"
                onClick={() => setSearchInput(searchInput)}
              >
                Search
              </Button> */}
            </form>
            // <InputDebounce
            //   value={state}
            //   variant="dark"
            //   sizeInput="small"
            //   onChange={(value) => setState(value)}
            //   placeholder={placeholder}
            // />
          )}
          {type === "select" && (
            <InputSelect id={label === "Seniority" ? "seniority-select" : ""} label={labelInput} options={options}
              state={state} setState={setState} placeholder={placeholder} isSearchable={isSearchable}
              isMulti={isMulti} menuPlacement={menuPlacement} />
          )}
        </div>
      )}
    </li>
  );
};
