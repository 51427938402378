import {ClientICPFilterOperatorENUM} from "../components/modules/SearchMain/SearchMainHelper";

export const replaceLabelByValue = (value: string) => {
    switch (value) {
        case ClientICPFilterOperatorENUM.IS_REQUIRED:
            return "Is Known";
        default:
            return value;
    }
};
