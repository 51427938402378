import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Loading from "components/atoms/Loading/Loading";
import PaymentMethod from "../PaymentMethod/PaymentMethod";
import { Elements } from "@stripe/react-stripe-js";
import getStripe from "utils/getStripe";
import { useLocation } from "react-router-dom";
import themes from "assets/themes";
import { getCreditSubscriptionPrice } from "utils/workWithData";
import { getCards } from "store/slices/card.slice";
import Heading from "components/atoms/layout/Heading/Heading";
import { Slider } from "@mui/material";
import SubscriptionOption from "../SubscriptionOptions/SubscriptionOptions";
import constants from "utils/constants";
import { ISubscription } from "types/transaction_subscription/subscription.type";
import InputField from "components/atoms/InputField/InputField";
import Icon from "components/atoms/Icon/Icon";
import { PaymentPlanType } from "utils/enums/paymentENUM";
import Button from "components/atoms/Button/Button";
import { getCouponItem, resetGetCouponItemData, resetGetCouponItemSuccess } from "store/slices/coupon.slice";
import { toast } from "react-toastify";
import { useAppContext } from "utils/context/AppContext";

const CREDITS_NUMBER_DEFAULT = 50
const BuyCredits: React.FC<any> = ({ isEditing, subscriptionData }) => {
  const { app } = useAppContext();
  const { isLoading } = useAppSelector((state) => state.cards);
  const { isLoading: couponIsLoading, isSuccess: couponIsSuccess, errorMessage: couponErrorMsg, data: coupon } = useAppSelector((state) => state.coupon);
  const { data: user } = useAppSelector((state) => state.user);
  const [creditsAmount, setCreditsAmount] = useState<number>(subscriptionData ? subscriptionData?.credits : CREDITS_NUMBER_DEFAULT);
  const [selectedOption, changeSelectedOption] = useState(subscriptionData ? subscriptionData?.interval : PaymentPlanType.ONE_TIME);
  const [price, setPrice] = useState<number | undefined>();
  const [updatedSubscriptionData, setUpdatedSubscriptionData] = useState(subscriptionData);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const handleChange = (event: Event | null, newValue: number | number[]) => {
    setCreditsAmount(newValue as number);
    isEditing && updateSubscriptionData({ credits: newValue });
  };
  const handleChangeRadio = (e: any) => {
    changeSelectedOption(e.currentTarget.value);
    isEditing && updateSubscriptionData({ interval: e.currentTarget.value });
    const frequency = e.currentTarget.value;
    const selectedPrice = calculatePrice(frequency);
    setPrice(selectedPrice);
  };
  const updateSubscriptionData = (updatedFields: { credits?: number | number[]; interval?: any; }) => {
    setUpdatedSubscriptionData((prevData: ISubscription) => ({ ...prevData, ...updatedFields }));
  };
  const calculatePrice = (frequency: string) => {
    const priceNew = getCreditSubscriptionPrice(creditsAmount, user.userId, coupon)
    if (frequency === PaymentPlanType.ONE_TIME) return priceNew?.oneTime;
    if (frequency === PaymentPlanType.MONTHLY) return priceNew?.monthlySubscription;
    if (frequency === PaymentPlanType.YEARLY) return priceNew?.annualSubscription;
  };
  const [couponInput, setCouponInput] = useState<string | number>('')
  const fetchCoupon = () => {
    if (!couponInput) { toast.error("Please enter a promo code"); return }
    dispatch(getCouponItem({ token: couponInput.toString().trim() }))
  }
  useEffect(() => {
    if (!couponIsSuccess) return
    if (coupon) toast.success("Coupon applied successfully!", { toastId: "coupon-applied" })
    else toast.warn(couponErrorMsg, { toastId: "coupon-error" })
    dispatch(resetGetCouponItemSuccess())
  }, [couponIsSuccess])
  useEffect(() => {
    setPrice(() => calculatePrice(selectedOption));
  }, [creditsAmount, coupon]); // eslint-disable-line
  useEffect(() => {
    isEditing && setCreditsAmount(subscriptionData ? Number(subscriptionData?.credits) : CREDITS_NUMBER_DEFAULT);
    changeSelectedOption(subscriptionData ? subscriptionData?.interval : PaymentPlanType.ONE_TIME);
    if (!subscriptionData) {
      const defaultPrice = getCreditSubscriptionPrice(CREDITS_NUMBER_DEFAULT, user.userId, coupon)?.oneTime;
      setPrice(defaultPrice);
    }
    // SegmentAgent.page("cartBuyCredits", { pathName: location.pathname });
    window.analytics.page("Buy Credits")
    if (location.state?.fromBurgerMenu) document.body.style.backgroundColor = themes["--bg-color"];
    dispatch(getCards());
  }, []);
  if (isLoading) return <Loading height="max-content" width="max-width" />
  return <div className={`${styles.main} flex flex-col gap-5 w-full`}>
    <Heading icon="credits" iconSize={34} title="Buy Credits" description="Credits are charged only for successfully enriched records." />
    <div className="flex flex-col gap-3 mt-2">
      <div className="flex items-center gap-2">
        <Icon name="credits" size={25} />
        <p className="font-bold text-lg text-left">Enter the amount of credits you want to buy</p>
      </div>
      <div className="flex items-center gap-5">
        <InputField
          value={creditsAmount}
          width="w-40" type="text" maxLength={5} onlyNumbers
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            let value = e.currentTarget.value as unknown as number;
            if (!value) value = 1
            if (value > 30000) value = 30000
            handleChange(null, value)
          }}
        />
        <Slider
          aria-label="credits"
          size="medium"
          value={creditsAmount}
          step={100}
          valueLabelDisplay="auto"
          min={50} max={30000}
          onChange={handleChange}
          sx={{
            "& .MuiSlider-thumb": { color: "var(--main-color)", },
            "& .MuiSlider-track": { color: "var(--main-color)", },
            "& .MuiSlider-markLabel": { color: "white", },
          }}
        />
      </div>
      <div className="flex px-4 items-center justify-center">
        <span className="font-bold text-sm tracking-wide uppercase badge primary">
          {`${creditsAmount?.toLocaleString()} credits ${selectedOption !== PaymentPlanType.ONE_TIME ? "every month" : ``}`}
        </span>
      </div>
    </div>
    {creditsAmount <= constants.CREDITS_PURCHASE_LIMIT_MAXIMUM
    ? <>
      {/* ⭐ Payment Plan */}
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-2">
          <Icon name="score" size={24} />
          <p className="font-bold text-lg text-left">Choose your Payment Plan</p>
        </div>
        <div className={styles.main_cost}><div className="grid grid-cols-3 w-full gap-5">
          {!isEditing && (
            <SubscriptionOption
              price={getCreditSubscriptionPrice(creditsAmount, user.userId, coupon)?.oneTime}
              frequency={PaymentPlanType.ONE_TIME}
              frequencyTitle="One-Time"
              selectedOption={selectedOption}
              handleChangeRadio={handleChangeRadio}
            />
          )}
          {(isEditing) && <>
            <SubscriptionOption
              price={getCreditSubscriptionPrice(creditsAmount, user.userId, coupon)?.monthlySubscription}
              oneTimePrice={getCreditSubscriptionPrice(creditsAmount, user.userId, coupon)?.oneTime}
              frequency={PaymentPlanType.MONTHLY}
              frequencyTitle="Monthly"
              selectedOption={selectedOption}
              handleChangeRadio={handleChangeRadio}
            />
            {/* <SubscriptionOption
              price={getCreditSubscriptionPrice(creditsAmount, user.userId, coupon)?.annualSubscription}
              oneTimePrice={getCreditSubscriptionPrice(creditsAmount, user.userId, coupon)?.oneTime}
              frequency={PaymentPlanType.YEARLY}
              frequencyTitle="Yearly"
              selectedOption={selectedOption}
              handleChangeRadio={handleChangeRadio}
            /> */}
          </>}
      </div></div></div>
      {/* ⭐ Promo Code */}
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-2">
          <Icon name="badge-percent" size={30} />
          <p className="font-bold text-lg">Promo Code</p>
        </div>
        {coupon
          ? <div className="bg-gray-800 p-3 rounded-xl border border-primary-500 flex justify-between items-center gap-2">
            <div className="bg-primary-500 rounded-full w-[45px] h-[45px] flex justify-center items-center text-white"><Icon name='check' size={36} /></div>
            <div className="text-left flex-1">
              <h3 className="text-base"><span className="uppercase font-bold">{coupon.token}</span> applied</h3>
              <p className="mt-1 text-sm md:text-base text-gray-400">{Number(coupon.discountPercent)}% off</p>
            </div>
            <div onClick={() => {dispatch(resetGetCouponItemData()); setCouponInput('')}} className="text-sm cursor-pointer hover:opacity-100 opacity-50" title="Remove Coupon"><Icon name='close' size={20} /></div>
          </div>
          : <div className="flex gap-3">
            <InputField
              disabled={couponIsLoading}
              value={couponInput}
              onChange={(e: React.FormEvent<HTMLInputElement>) => setCouponInput(e.currentTarget.value)}
              width="w-2/4" type="text" size="small" maxLength={12}
              placeholder="Enter your promo code here"
            />
            {couponIsLoading
              ? <Loading width="auto" height="auto" spinnerSize={22} />
              : <Button onClick={() => fetchCoupon()} size="sm">Apply</Button>}
          </div>
        }
      </div>
      {/* 💳 Payment Method */}
      <div className="flex flex-col gap-3 text-left">
        <div className="flex items-center gap-2">
          <Icon name="card" size={30} />
          <p className="font-bold text-lg text-left">Choose your Payment Method</p>
        </div>
        <Elements stripe={getStripe(app?.stripePublicKey)}>
          <PaymentMethod
            noHeader
            heightLimited
            enableCheckbox
            showPay
            isInModal
            numberOfCredits={creditsAmount}
            amount={price}
            isSubscription={selectedOption !== PaymentPlanType.ONE_TIME}
            frequency={selectedOption}
            isEditing={isEditing}
            subscriptionData={updatedSubscriptionData}
          />
        </Elements>
      </div>
    </>
    : <div className="flex flex-col items-start text-left gap-3">
      <Icon name="handshake" size={70} />
      <p className="text-xl font-bold">Interested in buying that many credits?</p>
      <p className="text-gray-400 text-base">
        We are happy to help you with your request. Please contact our team by the email{" "}
        <a className="link" href={`mailto:${constants.COMPANY_1_EMAIL_SUPPORT}`}>{constants.COMPANY_1_EMAIL_SUPPORT}</a>.
      </p>
      <p className="text-gray-400 text-base">Let's find out together the best plan for you so you can enjoy the full range of services we offer.</p>
    </div>}
  </div>
};

export default BuyCredits;
